// IE経由でのアクセス時にアラートを常に表示する
$(document).ready(function() {
  let ua = window.navigator.userAgent;
  let msie =/Edge\/|Trident\/|MSIE/.test(ua);
  if (!msie) { return; } // Return if not IE browser

  $('.container-fluid > .l-container').prepend(
    `
      <div class="c-alert--danger u-m10">
        <p>
          Internet Explorerは非対応です。<br>
          Chrome、Firefox、Safariのいずれかで利用してください。
        </p>
      </div>
    `
  );
})
